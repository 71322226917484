.navbar-nav link {
  position: relative;
  color: #10447e;
  margin: 0 1.2rem;
}
.link{
  position: relative;
  color: #10447e;
  margin: 0 0.6rem;
  font-size: 19px;
  text-decoration: none;
}
.link:hover {
  color: #072445;
}

.link::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  height: 2px;
  opacity: 0;
  bottom: 0;
  transition: all 0.5s;
  left: 0;
  background-color: #10447e;
}

.link:hover:after {
  opacity: 1;
}

.position-fixed {
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 999;
}

