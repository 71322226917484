.fixed-card-text {
  height: 250px;
}

.center {
  margin: 0 1rem;
}

.img-auto {
  height: 280px;
  object-fit: cover;
}

.btn-more {
  background-color: #378350;
  color: aliceblue;
  padding: 0.8rem;
  font-size: 1rem;
  font-weight: 500;
}

.btn-more:hover {
  transform: scale(1.1);
  background-color: aliceblue;
  color: #378350;
  transition: 0.3s ease;
}

.w-auto {
  width: auto;
}

.modal-image {
  width: 500px;
  height: auto;
}

.content {
  position: relative;
  width: 90%;
  max-width: 400px;
  margin: auto;
  overflow: hidden;
  border: none;
}

.content:hover {
  cursor: pointer;
}

.content:hover .content-overlay {
  opacity: 1;
}

.content-details {
  position: absolute;
  text-align: center;
  padding-left: 1em;
  padding-right: 1em;
  width: 100%;
  top: 50%;
  left: 50%;
  opacity: 0;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  -webkit-transition: all 0.3s ease-in-out 0s;
  -moz-transition: all 0.3s ease-in-out 0s;
  transition: all 0.3s ease-in-out 0s;
}

.content:hover .content-details {
  top: 65%;
  left: 50%;
  opacity: 1;
}

.content .content-overlay {
  background-color: rgba(151, 27, 27, 0.7);
  position: absolute;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  opacity: 0;
  -webkit-transition: all 0.4s ease-in-out 0s;
  -moz-transition: all 0.4s ease-in-out 0s;
  transition: all 0.4s ease-in-out 0s;
}

.content:hover .content-overlay {
  opacity: 1;
}