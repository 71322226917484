@import url('https://fonts.googleapis.com/css2?family=Mulish:wght@200;300;400;500;600;700;800&family=Roboto:ital,wght@0,300;1,300;1,400;1,500&display=swap');
*{
  outline: none;
  box-sizing: border-box;
  font-family: 'Mulish';
}
.mt-6{
  margin-top: 6rem;
}
.mt-7{
  margin-top: 7rem;
}
html,body,ul,ol{
  padding: 0;
  margin: 0;
}
.text-gray{
  color: #d6d6d6;
}
body{
  overflow-y: scroll !important;
}
/* width */
::-webkit-scrollbar {
  width: 6px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #10447E; 
  border-radius: 5px;
}
.fs-25{
  font-size: 25px;
}
/* Handle */
::-webkit-scrollbar-thumb {
  background: #10447E;
  border-radius: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background:#0e396b;
}
a{
  display: inline-block;
  text-decoration: none;
}
.bg-blue{
  background-color: #10447E;
}
button{
  border: none;
}
h1,h2,h3,h4,h5,h6,strong{
  color: #10447E !important;
  margin: 0;
}
figure{
  margin: 0;
}
.App {
  text-align: center;
}
