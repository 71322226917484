.sidebar .nav-link::after {
  content: none; /* :after içeriğini kaldırma */
}
.sidebar {
  height: 100%;
}
.sidebar .nav-link,
.nav-link:focus {
  color: aliceblue;
}

.sidebar .nav-link:hover,
.sidebar .nav-link:focus {
  color: rgb(190, 234, 235);
  font-weight: bold;
}
