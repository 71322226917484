.links li {
    list-style: none;
    margin-bottom: 0.6rem;
}
.links li a {
    text-decoration: none;
    color: aliceblue;
}
.links li a:hover {
    color: rgb(189, 214, 235);
}
.media-buttons a {
    margin-left: 20px;
    font-size: 25px;
    color: aliceblue;
}
.media-buttons a:hover {
    color: #10447E;
    background-color: aliceblue;
    border-radius: 30%;
    transform: scale(1.1);

}
